import React, { Component } from 'react';
import { DataGrid } from '@mui/x-data-grid';

class DataTable extends Component {
    render() {
        return (
            <div style={{ height: 700, width: '100%' }}>
                <DataGrid
                    rows={this.props.rows}
                    columns={this.props.columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                />
            </div>
        );
    }
}

export default DataTable;
