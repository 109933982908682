import React, { Component } from 'react';
import axios from 'axios';

import './App.css';

// import DangerButton from './components/DangerButton';
import DataTable from './components/Table';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'first_name', headerName: 'First name', width: 130 },
  { field: 'last_name', headerName: 'Last name', width: 130 },
  { field: 'email', headerName: 'Email', width: 250 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
    valueGetter: params => {
      const currentYear = new Date().getFullYear();
      const dobYear = new Date(params.row.date_of_birth).getFullYear();
      const age = currentYear - dobYear;
      return `${age}`;
    }
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: params =>
      `${params.row.first_name || ''} ${params.row.last_name || ''}`,
  },
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    axios.get('https://random-data-api.com/api/users/random_user?size=50')
      .then(results => this.setState({ data: results.data }))
      .catch(err => console.error(err));
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          {/* <p>
            <DangerButton
              text="Click Me!"
              />
          </p> */}
          <DataTable
            rows={this.state.data}
            columns={columns}
          />
        </header>
      </div>
    );
  }
}

export default App;
